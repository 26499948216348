export default [
    {
        headerName: null,
        checkboxSelection: true,
        pinned: 'left',
        sortable: false,
        filter: false,
        width: 70,
    },
    {
        headerName: 'School Name',
        field: 'schoolName',
        width: 250,
    },
    {
        headerName: 'District Id',
        field: 'districtId',
    },
    {
        headerName: 'Lea',
        field: 'lea',
    },
    {
        headerName: 'NCES School Code',
        field: 'ncesSchoolCode',
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
    },
    {
        headerName: 'Low Grade',
        field: 'lowGrade',
    },
    {
        headerName: 'High Grade',
        field: 'highGrade',
    },
    {
        headerName: 'School Type',
        field: 'schoolType',
    },
    {
        headerName: 'City',
        field: 'city',
    },
    {
        headerName: 'State',
        field: 'state',
    },
    {
        headerName: 'State Id',
        field: 'stateId',
    },
    {
        headerName: 'Site Group Name',
        field: 'siteGroupName',
    },
    {
        headerName: 'Block Imports',
        field: 'blockImport',
    },
    {
        headerName: 'Do not use master schedule',
        field: 'doNotUseMasterSchedule',
    },
]
